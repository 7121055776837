import { PAGE_NAME } from './types'

const INITIAL_STATE = {
  name: 'login',
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAGE_NAME: {
      return {
        ...state,
        name: action.payload
      }
    }
    default:
      return state
  }
}

export default reducer
