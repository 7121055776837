import Swal from 'sweetalert2'
import { SITE_ADDRESS } from "./GeneralVariables";
import i18next from "i18next";

export const apiErrorHandler = (e) => {
  if (e.message === 'Network Error') {
    Swal.fire({
      icon: 'error',
      title: `${i18next.t("لطفا اتصال اینترنت را بررسی فرمایید")}`,
      allowOutsideClick: false,
    })
  } else if (e.response?.['status'] === 400) {
    if (e.response?.data['errors']) {
      Swal.fire({
        icon: 'error',
        text: `${i18next.t("لطفا خطاهای به وجود آمده را رفع کنید")}`,
      })
      return { status: 400, error: e.response?.data['errors'] }
    } else {
      return { status: 400, error: e.response?.data['message'] }
    }
  } else if (e.response?.['status'] === 403) {
    if (e.response?.data['errors']?.['verify_code']) {
      return { status: 403, error: e.response?.data['errors'] }
    } else {
      Swal.fire({
        icon: 'error',
        title: `${i18next.t("خطای دسترسی")}`,
        text: `${i18next.t("شما به این بخش دسترسی ندارید")}`,
      })
    }
  } else if (e.response?.['status'] === 404) {
    window.location.replace(`${SITE_ADDRESS}/404`)
  } else if (e.response?.['status'] === 500 || e.response?.['status'] === 503) {
    Swal.fire({
      icon: 'error',
      title: `${i18next.t("خطای سرور")}`,
      text: `${i18next.t("خطای ناخواسته ای پیش آمده است")}`,
    })
    return { status: 500, error: `${i18next.t("خطای ناخواسته ای پیش آمده است")}` }
  } else if (e.response?.['status'] === 401) {
    Swal.fire({
      icon: 'error',
      title: `${i18next.t("عدم دسترسی")}`,
      text: `${i18next.t("احراز هویت نامعتبر")}`,
    })
    window.location.replace('/login')
    localStorage.removeItem('Token')
  }
}
