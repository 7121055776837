import { createStore, combineReducers } from 'redux'
import reducer from './page_name/reducer'
import mobile_reducer from './user_mobile/reducer'

let rootReducer = combineReducers({
  reducer,
  mobile_reducer
})

export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
