import React, {lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import { useTranslation } from "react-i18next";

const VerifyCode = lazy(() => import("../../pages/login/VerifyCode"));
const NumberCheck = lazy(() => import("../../pages/login"));
const TorRequest = lazy(() => import("../../pages/tor-request/torRequest"));
const GorfehReservation = lazy(() => import("../../pages/GorfehReservation"));
const GorfehSazi = lazy(() => import("../../pages/booth-building"));
const UserInfo = lazy(() => import("../../pages/booth-building/userInfo"));
const GorfehInfo = lazy(() => import("../../pages/booth-building/gorfehInfo"));
const Result = lazy(() => import("../tor-request/Result"));
const Getaway = lazy(() => import("../../pages/getaway"));
const GetawayResponse = lazy(() => import( "../../pages/getaway/GetawayResponse"));

function Index(
    {
        language,
        params,
        activity_area,
        time_to_move,
        time_is_back,
        tour_price,
        hotel_tour_list,
        type_flight_class_list,
        exhibition_title,
        air_logo,
        gorfehHandler,
        result
    }) {

  const{t}=useTranslation()

    return (
        <Routes>
            <Route
                path={`tour-request`}
                element={<TorRequest activity_area={activity_area} exhibition_title={exhibition_title}/>}></Route>
            <Route
                path={"booth-reservation"}
                element={<GorfehReservation activity_area={activity_area}
                                            exhibition_title={exhibition_title}/>}></Route>

            <Route path="booth-building" element={<Navigate to="/booth-building/user" replace/>}/>
            <Route
                exact path="booth-building"
                element={<GorfehSazi activity_area={activity_area} exhibition_title={exhibition_title}/>}>
                <Route exact path="user" element={<UserInfo exhibition_title={exhibition_title}/>}/>
                <Route exact path="info"
                    element={<GorfehInfo activity_area={activity_area} gorfehHandler={gorfehHandler}/>}/>
                <Route
                    exact
                    path="result"
                    element={
                        <Result label={`${t("سفارش")}`} date={result?.date} code={result?.code}
                                customer={result?.customer}/>}/>
            </Route>
            <Route path={"getaway"} element={<Getaway
                time_to_move={time_to_move}
                time_is_back={time_is_back}
                tour_price={tour_price}
                hotel_tour_list={hotel_tour_list}
                type_flight_class_list={type_flight_class_list}
                activity_area={activity_area}
                air_logo={air_logo}
                exhibition_title={exhibition_title}/>}></Route>
            <Route index element={<NumberCheck language={language} params={params}/>}></Route>
            <Route path="*" element={<NumberCheck language={language} params={params}/>}/>
            <Route path={"login"} element={<NumberCheck language={language} params={params}/>}></Route>
            <Route path={"verify_code"} element={<VerifyCode language={language} params={params}/>}></Route>
            <Route path={"getaway-response"} element={<GetawayResponse params={params}/>}></Route>
        </Routes>
    );
}

export default Index;