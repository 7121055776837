import React, { Suspense, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Provider } from "react-redux";
import store from "./redux/store";

import large_bg_url from "./assets/images/empty-bg.jpg";
import mini_bg_url from "./assets/images/empty-bg.jpg";
// components
import BackgroundTop from "./components/background-top";
import FormSection from "./components/form-section";
import Routes from "./components/routes";
import { getData } from "./assets/script/GeneralFunctions";
import { SITE_ADDRESS } from "./assets/script/GeneralVariables";
import './i18n'
import {useTranslation} from "react-i18next";


AOS.init({
  once: true
});

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <App />
    </Provider>
  );
};


function App() {

  const [result, setResult] = useState({});
  const [params, setParams] = useState({});
  const [page_data_obj, setPageDataObj] = useState({});
  const [loader, setLoader] = useState(true);
  const[language,setLanguage]=useState()

  const {i18n} = useTranslation();




  async function fetchData() {
    if (window.location?.search) {
      let type = window.location?.search?.split("&type=")[1]?.split("&")[0];
      let id = window.location?.search?.split("&type=")[0]?.split("=")[1];

      let lang =  window.location?.search?.split("&lang=")[1]
      setLanguage(lang)
      i18n.changeLanguage(`${lang}`)
      setParams({
        id, type,lang
      });

      let formData = new FormData();

      formData.append("data[action]", "getData");
      formData.append("data[params][id]", id);
      formData.append("data[params][type]", type);
      if(lang) {
        formData.append("data[params][lang]", lang);
      }

      const apiResult = await getData(SITE_ADDRESS, '/api/', 'post',formData,false,false,true)
      if (apiResult.status === 200) {
        setLoader(false);
        let data = JSON.stringify(apiResult?.data);
        localStorage.setItem("page_data", encodeURI(data));
        setPageDataObj({
          "activity_area": apiResult?.data?.activity_area,
          "exhibition_title": apiResult?.data?.title,
          "breadcrumb": apiResult?.data?.breadcrumb,
          "time_to_move": apiResult?.data?.time_to_move,
          "time_is_back": apiResult?.data?.time_is_back,
          "tour_price": apiResult?.data?.tour_price,
          "hotel_tour_list": apiResult?.data?.hotel_tour_list,
          "type_flight_class_list": apiResult?.data?.type_flight_class_list,
          "air_logo": apiResult?.data?.air_logo
        });
      }
    }
  }



  useEffect(() => {
    fetchData();

  }, [window.location.href]);

  useEffect(() => {

    if (localStorage.getItem("page_data")) {
      setLoader(false);
      let user_info_data = localStorage.getItem("page_data");
      user_info_data = JSON.parse(decodeURI(user_info_data));

      setPageDataObj({
        "activity_area": user_info_data?.activity_area,
        "exhibition_title": user_info_data?.title,
        "breadcrumb": user_info_data?.breadcrumb,
        "time_to_move": user_info_data?.time_to_move,
        "time_is_back": user_info_data?.time_is_back,
        "tour_price": user_info_data?.tour_price,
        "hotel_tour_list": user_info_data?.hotel_tour_list,
        "type_flight_class_list": user_info_data?.type_flight_class_list,
        "air_logo": user_info_data?.air_logo
      });
    } else {
      fetchData();
    }

  }, [localStorage.getItem("page_data")]);

  const gorfehHandler = (gorfeh_result) => {
    setResult(gorfeh_result);
  };

  return (
    <Suspense fallback={<p>Loading ...</p>}>
      {
        loader ? <p>Loading ...</p> :
          <div
            dir={language ? 'ltr' : 'rtl'}
            data-testid={"main-section"}
            className={`${language ? 'dv-ltr' : ''} relative w-full flex flex-col items-center min-h-screen`}>
            <BackgroundTop
              large_bg_url={large_bg_url}
              mini_bg_url={mini_bg_url}
            />
            <FormSection url_list={page_data_obj?.breadcrumb}>
              <Routes
                language={language}
                params={params}
                exhibition_title={page_data_obj?.exhibition_title}
                activity_area={page_data_obj?.activity_area}
                time_to_move={page_data_obj?.time_to_move}
                time_is_back={page_data_obj?.time_is_back}
                tour_price={page_data_obj?.tour_price}
                hotel_tour_list={page_data_obj?.hotel_tour_list}
                type_flight_class_list={page_data_obj?.type_flight_class_list}
                air_logo={page_data_obj?.air_logo}
                gorfehHandler={gorfehHandler}
                result={result}
              />
            </FormSection>
          </div>
      }
    </Suspense>
  );
}

export default AppWrapper;
