import React, { memo } from "react";

function Index({ url_list, children }) {
  return (
    <div className="lg:absolute mt-4 mx-4 lg:mt-[7%]">
      <div
        data-testid={'form-section'}
        className={"w-full mb-20 max-w-md sm:max-w-2xl md:max-w-3xl dl:max-w-4xl lg:max-w-5xl xl:max-w-full 2xl:w-[1400px] print:max-w-full print:w-full print:mb-2 mx-auto p-8 bg-white rounded-lg z-10 shadow-form-content print:shadow-none"}>
        <div className="flex items-center justify-center flex-wrap pb-3" >
          {
            url_list && url_list.map((url, index) =>
              <div
                key={index}
                className={`hidden md:flex items-center justify-center text-url-list ${url_list?.length - 1 !== index && "after:content-left-arrow ltr:after:-scale-x-100"}`}>
              <span
                className={`font-moraba text-sm text-url-list px-2 font-normal pb-1`}>
                  {url}
              </span>
              </div>
            )
          }
        </div>
        {children}
      </div>
    </div>
  );
}

export default memo(Index);