import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";

function Index({ mini_bg_url, large_bg_url }) {

  const [image_url, setImageUrl] = useState('')

  useEffect(()=>{
    setImageUrl(
      window.innerWidth > 768 ? large_bg_url : mini_bg_url
    )
  },[mini_bg_url, large_bg_url])

  return (
    <div
      data-testid={"background"}
      className={'print:hidden bg-left-top w-full bg-fit bg-no-repeat h-64 lg:h-[260px]'}
      style={{backgroundImage: `url(${image_url})`}}
    >
    </div>
  );
}

Index.propTypes = {
  mini_bg_url: PropTypes.string.isRequired,
  large_bg_url: PropTypes.string.isRequired,
};

export default memo(Index);
