import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import Compress from "compress.js";
import { MAIN_URL } from "./GeneralVariables";
import { apiErrorHandler } from "./errorHandling";
import i18next from "i18next";


let authToken = localStorage.getItem("Token");
export const getData = async (
  base_url,
  url,
  type,
  dataParams = {},
  isToken = false,
  isLoader = true,
  isHeaderJson = false,
  default_token = null
) => {
  if (isLoader) {
    loader(true);
  }
  let header = {};
  if (isToken && default_token) {
    header = { headers: { Authorization: `Bearer ${default_token}` } };
    if (isHeaderJson) {
      header = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${default_token}`,
          Accept: "*/*"
          // "Accept-Encoding": "gzip, deflate, br",
          // Connection: "keep-alive"
        }
      };
    }
  } else if (isToken) {
    header = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Access-Control-Allow-Origin": "http://localhost:3000"
      }
    };
    if (isHeaderJson) {
      header = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${authToken}`,
          Accept: "*/*",
          // "Accept-Encoding": "gzip, deflate, br",
          "Access-Control-Allow-Origin": "*/*",
          // 'Access-Control-Allow-Origin':'http://localhost:3000',
          "Access-Control-Allow-Credentials": "true"
          // Connection: "keep-alive"
        }
      };
    }
  }

  if (type === "post") {
    let formData;
    if (isHeaderJson) {
      formData = dataParams;
    } else {
      formData = new URLSearchParams();
      for (let key in dataParams) {
        formData.append(key, dataParams[key]);
      }
    }
    try {
      const { data, status } = isToken
        ? await axios.post(base_url + url, formData, header)
        : await axios.post(base_url + url, formData);
      loader();
      if (status === 200) {
        for (let key in data.messages) {
          toast.success(data.messages[key]);
        }
        return { status: status, data: data };
      }
    } catch (e) {
      loader();
      return apiErrorHandler(e);
    }
  } else {
    let formData = "?";

    if (
      Object.keys(dataParams).length === 0 &&
      dataParams.constructor === Object
    ) {
      formData = "";
    }
    for (let key in dataParams) {
      formData +=
        formData.slice(-1) === "?"
          ? `${key}=${dataParams[key]}`
          : `&${key}=${dataParams[key]}`;
    }

    try {
      const { data, status } = isToken
        ? await axios.get(base_url + url + formData, header)
        : await axios.get(base_url + url + formData);
      loader();
      if (status === 200) {
        for (let key in data.messages) {
          toast.success(data.messages[key]);
        }
        return { status: status, data: data };
      } else {
        toast.error(`${i18next.t("خطای ناخواسته ای پیش آمد")}`);
        return false;
      }
    } catch (e) {
      loader();
      return apiErrorHandler(e);
    }
  }
};

export const getFormDataPost = async (url, formData, is_loader) => {
  let header = {};
  header = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, HEAD, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      Authorization: `Bearer ${authToken}`
    }
  };
  if (is_loader !== false) loader(true);
  try {
    const { data, status } = await axios.post(MAIN_URL + url, formData, header);
    loader();
    return { status: status, data: data };
  } catch (e) {
    loader();
    return apiErrorHandler(e);
  }
};
export const getFormDataPostWithoutMainUrl = async (url, formData, is_loader) => {
  let header = {};
  header = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Accept" : "*/*",
      Authorization: `Bearer ${authToken}`
    }
  };
  if (is_loader !== false) loader(true);
  try {
    const { data, status } = await axios.post(url, formData, header);
    loader();
    return { status: status, data: data };
  } catch (e) {
    loader();
    return apiErrorHandler(e);
  }
};

export const loader = (state = false) => {
  try {
    state
      ? document.getElementById("loader").classList.remove("hidden")
      : document.getElementById("loader").classList.add("hidden");
  } catch (e) {
  }
};

export const setTitle = (title) => {
  document.title = title;
};

function updateTextView(_obj) {
  let num = getNumber(_obj);
  if (num === 0) {
    _obj = "";
  } else {
    _obj = num.toLocaleString();
  }
  return _obj;
}

function getNumber(_str) {
  let arr = _str?.split("");
  let out = [];
  for (let cnt = 0; cnt < arr?.length; cnt++) {
    if (isNaN(arr[cnt]) === false) {
      out?.push(arr[cnt]);
    }
  }
  return Number(out?.join(""));
}

export const CommaFormat = (str) => {
  return updateTextView(str);
};
export const fixNumber = (str) => {
  let persianNumbers = [
      /۰/g,
      /۱/g,
      /۲/g,
      /۳/g,
      /۴/g,
      /۵/g,
      /۶/g,
      /۷/g,
      /۸/g,
      /۹/g
    ],
    arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
  if (typeof str === "string") {
    for (let i = 0; i < 10; i++) {
      str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
    }
  }
  return str;
};

export const priceFormat = (price) => {
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Mobile Number regex
export const mobileRegex = (mobile) => {
  let numRegex = /^(\+98|0)9\d{9}$/;

  if (!numRegex.test(mobile)) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: `${i18next.t("شماره موبایل نامعتبر است")}`,
      showConfirmButton: false,
      timer: 1500
    });
    return false;
  }
};

export const edit_item_with_index = (lastItems, editItem, index) => {
  return lastItems.map((item, i) => {
    if (i === parseInt(index)) {
      return { ...editItem };
    } else {
      return { ...item };
    }
  });
};

export const editedItemsWithColumn = (lastItems, newItem) => {
  return lastItems.map((item) => {
    if (item.column === newItem?.column) {
      return { ...newItem };
    } else {
      return { ...item };
    }
  });
};

export const edit_item_with_id = (lastItems, editItem) => {
  return lastItems.map((item) => {
    if (parseInt(item?.id) === parseInt(editItem?.id)) {
      return { ...editItem };
    } else {
      return { ...item };
    }
  });
};

// Remove

export const removeItemOnce = (arr, index) => {
  arr.splice(index, 1);
  return arr;
};

export const remove_item_with_object = (arr, item) => {
  let new_array = [];
  arr.map((itm) => {
    if (JSON.stringify(item) !== JSON.stringify(itm)) {
      new_array.push(itm);
    }
  });
  return new_array;
};

export const remove_item_of_arr_with_id = (arr, id) => {
  let new_array = [];
  arr.map((itm) => {
    if (id !== itm.id) {
      new_array.push(itm);
    }
  });
  return new_array;
};

export const remove_item_of_arr_with_status = (arr, status, type) => {
  let new_array = [];
  if (arr?.length > 0) {
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i]?.type !== type || arr[i]?.status !== status) {
        new_array = [...new_array, arr[i]];
      }
    }
  }
  return [...new Set(new_array)];
};

export const remove_item_of_arr_with_id_status = (arr, id, status, type) => {
  let new_array = [];
  if (arr?.length > 0) {
    for (let i = 0; i < arr?.length; i++) {
      if (
        arr[i]?.id !== id ||
        arr[i]?.type !== type ||
        arr[i]?.status !== status
      ) {
        new_array = [...new_array, arr[i]];
      }
    }
  }
  return [...new Set(new_array)];
};

export const fileUpload = async (e) => {
  if (e) {
    let file = e;

    const compress = new Compress();
    const resizedImage = await compress.compress([file], {
      size: 5, // the max size in MB, defaults to 5 MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth: 1080, // the max width of the output image, defaults to 1920px
      maxHeight: 768, // the max height of the output image, defaults to 1920px
      resize: true // defaults to true, set false if you do not want to resize the image width and height
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);

    let file_name = new File([resizedFile], file?.name, {
      lastModified: file?.lastModified,
      type: file?.type
    });
    return file_name;
  }
};

// remove Swal
// const swalWithBootstrapButtons = Swal.mixin({
//     customClass: {
//         confirmButton: 'btn btn-success',
//         cancelButton: 'btn btn-danger'
//     },
//     buttonsStyling: false
// })
// swalWithBootstrapButtons.fire({
//     title: 'آیا مطمئن هستید؟',
//     text: "میخواهید این مورد را حذف کنید",
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonText: 'بله حذف کن',
//     cancelButtonText: 'نه حذف نشود',
//     reverseButtons: true
// }).then(async (result) => {
//     if (result.isConfirmed) {
//         let remove_result = await getData(MAIN_URL, `account/portfolio/remove/${id}`, 'get', {}, token, false);
//         if (remove_result?.status === 200) {
//             swalWithBootstrapButtons.fire(
//                 'حذف شد!',
//                 'با موفقیت حذف شد',
//                 'success'
//             )
//             setRender(!is_render)
//         }
//     } else if (
//         /* Read more about handling dismissals below */
//         result.dismiss === Swal.DismissReason.cancel
//     ) {
//         swalWithBootstrapButtons.fire(
//             'لغو شد',
//             'بدون تغییر باقی ماند',
//             'error'
//         )
//     }
// })

// success Swal
// Swal.fire({
//     position: 'top-end',
//     icon: 'success',
//     title: 'Your work has been saved',
//     showConfirmButton: false,
//     timer: 1500
// })

export const just_persian = (str) => {
  let persian_rgx =
    /^$|^([\u0600-\u0659\s]|[\u0670-\u06EF\s]|[\u06FA-\u06FF\s])+$/;

  let banned_numbers = /^([A-Za-z]|[0-9])+$/;
  if (!persian_rgx.test(str) || banned_numbers.test(str)) {
    return false;
  }
  return true;
};

export const just_number = (str) => {
  let num_regex = /^([[0-9]|[۰-۹])*$/;
  if (!num_regex.test(str)) {
    return false;
  } else {
    return true;
  }
};

// program to remove duplicate value from an array

export const getUnique = (arr) => {
  let uniqueArr = [];
  // loop through array
  for (let i of arr) {
    if (uniqueArr.indexOf(i) === -1) {
      uniqueArr.push(i);
    }
  }
  return uniqueArr;
};

export const setCookie = (cname, cvalue) => {
  document.cookie = cname + "=" + cvalue + ";";
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
