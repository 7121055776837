import { USER_MOBILE ,USER_EMAIL} from './types'

const INITIAL_STATE = {
  mobile: '',
  email: ''
}

const mobile_reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_MOBILE: {
      return {
        ...state,
        mobile: action.payload
      }
    }
    case USER_EMAIL: {
      return {
        ...state,
        email: action.payload
      }
    }
    default:
      return state
  }
}

export default mobile_reducer
